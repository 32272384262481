/* form.module.css */

.App {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    transition: transform 0.2s;
    width: 500px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
}


fieldset {
    border: none;
}

input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 12px;
    margin-bottom: 15px; /* Ensure margin is added back if needed */
}



select {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
}

label {
    font-size: 15px;
    display: block;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 5px;
    text-align: left;
    color: #555;
    font-weight: bold;
}

button {
    padding: 15px;
    border-radius: 10px;
    margin: 15px;
    border: none;
    color: white;
    cursor: pointer;
    background-color: #4caf50;
    width: 40%;
    font-size: 16px;
}

button:hover{
    background-color:orangered;
}

textarea {
    resize: none;
    width: 100%;
    min-height: 100px;
    max-height: 150px;
    padding:5px;
}
