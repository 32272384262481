/* BasicExample.module.css */
.container {
  text-align: center;
  margin-bottom: 2rem;
  width: 80%;
  margin: auto;
  /* border: 2px solid rgb(245, 241, 241); */
  padding: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s; /* Added transition for hover effect */
}

.container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Light shadow on hover */
  transform: scale(1.02); /* Slight scale effect on hover */
}

  
  .title {
    font-size: 3rem;
    color: black;
    margin-bottom: 0.5rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: grey;
    margin-bottom: 1rem;
  }
  
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  
  
  .icons {
    margin-bottom: 1rem;
  }
  
  .icon {
    margin: 0 10px;
    color: black;
    transition: color 0.2s;
   
  }
  
  .icon:hover {
    color: orangered;
  }
  

  
  
  .cardImage {
    width: 100%;
    height: 320px;
    object-fit: cover;
  }
  
  .cardBody {
    padding: 1rem;
  }
  
  .cardTitle {
    font-size: 1.85rem;
    margin-bottom: 0.5rem;
    color: black;
    font-weight: bold;;
  
  }
  
  .card:hover .cardTitle {
    color:orangered;
  }
  
  .cardText {
    font-size: 1rem;
    color: #555;
    
  }
  .cardTextlast{
    margin-bottom: 2rem;
    font-size: 1rem;
    color: #555;
  }
  .cardButton {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cardButton:hover {
    background-color: #0056b3;
  }
  