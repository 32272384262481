/* BasicExample.module.css */
.container {
  text-align: center;
  margin-bottom: 2rem;
  
}

.title {
  font-size: 3rem;
  color: black;
  margin-bottom: 0.5rem;
 
}

.subtitle {
  font-size: 1.2rem;
  color: grey;
  margin-bottom: 1rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}


.icons {
  margin-bottom: 1rem;
}

.icon {
  margin: 0 10px;
  color: black;
  transition: color 0.2s;
 
}

.icon:hover {
  color: orangered;
}
.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 26rem; /* Increased width */
  height: 30.5rem; /* Increased height */
  margin: 1rem;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}



/* .cardImage {
  width: 100%;
  
  object-fit: cover;
 
} */

.cardImage {
  width: 100%; /* Ensures the image takes the full width of the container */
  height: 340px; /* Maintains the aspect ratio of the image */
  object-fit: contain; /* Ensures the image covers the container, cropping if necessary */
  border-radius: 8px; 
 
}


.cardBody {
  padding: 1rem;
}

.cardTitle {
  font-size: 1.85rem;
  margin-bottom: 0.5rem;
  color: black;
  font-weight: bold;;

}

.card:hover .cardTitle {
  color:orangered;
}

.cardText {
  font-size: 1rem;
  color: #555;
  
}
.cardTextlast{
  margin-bottom: 2rem;
  font-size: 1rem;
  color: #555;
}
.cardButton {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cardButton:hover {
  background-color: #0056b3;
}


@media (max-width: 480px) {
  .card {
    width: 100%; /* Smaller width for tablets */
    height: 140px;
    display:flex;
    flex-direction:row;
    
  }

  .cardText {
    font-size: 15px;
  }

  .cardTextlast {
    font-size: 40px;
  }

  .cardImage {
    width: 150px;
    height: 140px;
  }

  .cardTitle {
    font-size: 20px;
    display: block; /* Ensure it defaults to block */
  }

  .cardTitle,
  .cardImage {
    display: flex; /* Set both cardTitle and cardImage to flex */
    align-items: center; /* Center align items */
  }
 
}
